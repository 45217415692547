<template>
  <div>
    <div class="p-3 pre-box title-panel">
      <b-row :set="(v = reportList)">
        <b-col sm="3 border-right">
          <label>Total Stampcard</label>
          <div>
            <span class="text-score">
              {{ v.stampcard_user_count }} คน
              <!-- {{ form.visit_campaign | numeral("0,0") }} -->
            </span>
          </div>
        </b-col>
        <b-col sm="3 border-right">
          <label>Number Of Stamp (At least 1)</label>
          <div>
            <span class="text-score">
              {{ v.stampcard_exist_count | numeral("0,0") }} คน
            </span>
          </div>
        </b-col>
        <b-col sm="3 border-right">
          <label>Redeem Stamp Rewards</label>
          <div>
            <span class="text-score">
              {{ v.stampcard_reward_count | numeral("0,0") }} คน

              <div class="badge badge-score">
                <!-- ({{ form.total_new_customer }} New User) -->
              </div>
            </span>
          </div>
        </b-col>
        <b-col sm="3">
          <label>% Number of Redeemed Reward</label>
          <div>
            <span class="text-score">
              <!-- {{
         (form.total_spending / form.budget) | numeral("0,0") 
            }} -->
              {{ checkNumber(v.stampcard_reward_percen) }} %
            </span>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <HeaderPanel
        title=""
        :filter="filter"
        placeholder="Search Name , Member ID"
        @search="$emit('getData')"
        @sidebar="toggleSideBar"
        :hasExport="true"
        @export="exportReport"
        :hasDropdown="false"
        class="mb-2 mb-lg-3"
      />
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(name)="data">
              <div>
                {{ data.item.name }}

                <div class="f-12">{{ data.item.member_id }}</div>
              </div>
            </template>
            <template v-slot:cell(stampcard_date)="data">
              <div>{{ data.item.stampcard_date | moment($formatDateNew) }}</div>
              <div class="time-color">
                {{ data.item.stampcard_date | moment("HH:mm:ss") }}
              </div>
            </template>
            <template v-slot:cell(expire_date)="data">
              <div>{{ data.item.expire_date | moment($formatDateNew) }}</div>
              <div class="time-color">
                {{ data.item.expire_date | moment("HH:mm:ss") }}
              </div>
            </template>
            <template v-slot:cell(stamp)="data">
              <div>{{ data.item.stamp }}/{{ countStamp }}</div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <Pagination
        @handleChangeTake="$emit('handleChangeTake')"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="$emit('pagination')"
      />
    </div>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :hideStatusFilter="false"
      :placeholder="'Search Name'"
      @searchAll="$emit('getData')"
      @clearFilter="$emit('clearFilter')"
    >
      <template v-slot:filter-option>
        <div class="mt-3">
          <div>
            <div class="font-weight-bold mb-2">Redeemed Date</div>
            <div :class="['input-container']">
              <datetime
                format="dd/MM/yyyy"
                v-model="filter.redeem_date"
                ref="startDate"
                @input="handleMinDate"
                placeholder="Please Select Date"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.startDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mt-3 mb-3">
            <div class="font-weight-bold mb-2">Expiration Date</div>
            <div :class="['input-container']">
              <datetime
                format="dd/MM/yyyy"
                v-model="filter.expire_date"
                :min-datetime="filter.redeem_date"
                placeholder="Please Select Date"
                ref="endDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.endDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import SideBarFilter from "@/components/SideBarFilter";
export default {
  components: { SideBarFilter },
  props: {
    reportList: {
      required: true,
    },
    filter: {
      required: true,
    },
    rows: {
      required: true,
    },
    countStamp: {
      required: true,
    },
    stampName: {
      required: false,
    },
  },
  data() {
    return {
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      fields: [
        {
          key: "name",
          label: "Name / Member I",
          tdClass: "text-left",
          thClass: "text-left",
        },
        {
          key: "stamp",
          label: "Stamp",
        },
        {
          key: "stampcard_date",
          label: "Redeemed Date",
        },
        {
          key: "expire_date",
          label: "Expiration Date",
        },
      ],
      items: this.reportList.stampcard_list,
      isBusy: false,
    };
  },
  methods: {
    async exportReport() {
      this.$EventBus.$emit("showLoading");

      let data = await this.$Axios.post(
        `${process.env.VUE_APP_API}/StampCard/export/${this.$route.params.id}`,
        this.filter,
        {
          responseType: "blob",
        }
      );
      if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        var dateExcel = this.$moment().format("DD-MM-YYYY");
        let stampName = this.stampName.replace(" ", "-");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `report-${stampName}-${dateExcel}.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        this.errorAlert("Export Fails");
      }

      this.$EventBus.$emit("hideLoading");
    },
    checkNumber(val) {
      return val ? (val == "NaN" ? 0 : val.toFixed(2)) : 0;
    },
    handleMinDate() {
      let diff = this.$moment(this.filter.expire_date).diff(
        this.$moment(this.filter.redeem_date),
        "day"
      );

      if (diff < 0) this.filter.expire_date = "";
    },
    toggleSideBar() {
      this.$refs.sidebarFilter.show();
    },
  },
};
</script>

<style>
.text-score {
  color: var(--primary-color);
  font-size: 38px;
}
</style>
